import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const DEFAULT_MESSAGES: string[] = ["Just a moment...", "It's almost done..."];

interface BackdropLoadingProps {
    open: boolean,
    message: string
}

export default function BackdropLoading(props: BackdropLoadingProps) {
    const [message, setMessage] = useState<string>(props.message);

    useEffect(() => {
        if (props.open)
            handleMessageChange();
        else
            setMessage(props.message);
    }, [props.open]);

    const handleMessageChange = () => {
        if (props.open) {
            DEFAULT_MESSAGES.forEach((message, index) => {
                setTimeout(() => setMessage(message), 6000 * (index + 1));
            });
        } else {
            setMessage(props.message);
        }
    };

    return (
        <Backdrop open={props.open} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Grid>
                <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
                    <CircularProgress color="inherit" />
                </Grid>

                <Grid item xs={12}>
                    <Typography color="inherit" variant="h6">{message}</Typography>
                </Grid>
            </Grid>
        </Backdrop>
    );
}