import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { GridToolbar, TableContainer } from 'wcz-x-data-grid';
import moment from 'moment';
import React, { useContext, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchGet, LayoutContext } from 'wcz-layout';
import Defect from '../models/Defect';
import DefectCategory from '../models/DefectCategory';
import { apiUrl } from '../utils/BaseUrl';

export default function Defects() {
    const { changeTitle, i18n, t } = useContext(LayoutContext);

    const { data: defects = [] } = useQuery<Defect[]>(["defects"], ({ signal }) => fetchGet(`${apiUrl}/v1/defect`, signal));

    useEffect(() => changeTitle(t("Defects")), [i18n.language]);

    const columns: GridColDef[] = useMemo(() => [
        { field: 'isFixed', headerName: t("Fixed"), width: 100, type: 'boolean' },
        { field: 'description', headerName: t("Description"), width: 300, },
        {
            field: 'category', headerName: t("Category"), width: 150, valueFormatter: params => {
                const category = params.value as DefectCategory;
                if (i18n.language === "cs")
                    return category.nameCz;

                return category.name;
            }
        },
        { field: 'usn', headerName: "USN", width: 200, },
        { field: 'personInCharge', headerName: t("PersonInCharge"), width: 200, },
        { field: 'createdOnStage', headerName: t("CreatedOnStage"), width: 200, },
        { field: 'created', headerName: t("Created"), width: 140, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'createdBy', headerName: t("CreatedBy"), width: 220, },
        { field: 'lastUpdated', headerName: t("Updated"), width: 140, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'lastUpdatedBy', headerName: t("UpdatedBy"), width: 220, },
    ] as GridColDef[], [i18n.language]);

    return (
        <TableContainer>
            <DataGridPremium rows={defects} columns={columns} slots={{ toolbar: GridToolbar }} />
        </TableContainer>
    );
}