import { Box, Button, Container, Grid, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { fetchGet, fetchPost, LayoutContext } from 'wcz-layout';
import Checklist from '../models/Checklist';
import { ChecklistStatus } from '../models/enums/ChecklistStatus';
import { apiUrl } from '../utils/BaseUrl';
import { DynamsoftScanner } from '../components/common/DynamsoftScanner';

export default function Home() {
    const [usn, setUsn] = useState<string>("");
    const [scanOpen, setScanOpen] = useState<boolean>(false);
    const { changeTitle, snackbar, t, i18n } = useContext(LayoutContext);
    const navigate = useNavigate();

    const { data: checklists = [], isFetched } = useQuery<Checklist[]>(["checklists"], ({ signal }) => fetchGet(`${apiUrl}/v1/checklist`, signal), {
        cacheTime: 0
    });

    useEffect(() => changeTitle(t("Checklists")), [i18n.language]);

    const { mutate: createChecklist, isLoading: isCreating } = useMutation(() => fetchPost(`${apiUrl}/v1/checklist/${usn}`, null), {
        onSuccess: (data: Checklist) => navigate('/checklist/edit/' + data.id),
    });

    const findByUsn = (usn: string) => {
        if (isCreating)
            return;

        const checklist: Checklist | undefined = checklists.find(ch => ch.usn === usn);

        if (checklist?.status === ChecklistStatus.Finished)
            return snackbar({ message: "Checklist is finished", severity: "info" });
        else if (checklist) {
            return navigate('/checklist/edit/' + checklist.id);
        }

        createChecklist();
    };

    const confirmOnEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && usn) {
            findByUsn(usn);
        }
    };

    const onScan = (value: string) => {
        setUsn(value);
        setScanOpen(false);
        findByUsn(value);
    };

    if (!isFetched)
        return null;

    return (
        <React.Fragment>
            <Container>
                <Grid container justifyContent="center" sx={{ mt: 5 }}>
                    <Grid item xs={12} md={6}>
                        <TextField label="USN" variant="outlined" fullWidth autoFocus value={usn} onChange={(e) => setUsn(e.target.value)} onKeyDown={confirmOnEnter}
                            InputProps={{ autoComplete: 'off' }} />

                        <Box sx={{ float: 'right', mt: 2 }}>
                            <Button variant="outlined" color="primary" onClick={() => setScanOpen(true)} sx={{ mr: 1 }}>Scan</Button>
                            <Button variant="contained" color="primary" disabled={!usn} onClick={() => findByUsn(usn)}>{t("Open")}</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <DynamsoftScanner open={scanOpen} setOpen={setScanOpen} onScan={onScan} />
        </React.Fragment>
    );
}