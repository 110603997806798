import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Chip, Grid, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "wcz-layout";
import Template from "../../models/Template";

interface TemplateDetailProps {
    template: Template,
    setTemplate: (template: Template) => void
}

export default function TemplateDetail(props: TemplateDetailProps) {
    const [upnPreview, setUpnPreview] = useState<string[]>([] as string[]);
    const { t } = useContext(LayoutContext);

    useEffect(() => {
        if (props.template.upns)
            setUpnPreview(props.template.upns.split(/[\r\n\s|;|,]/g));

    }, [props.template.upns]);

    return (
        <Grid item xs={12}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}><Typography variant="h6">{t("Template")}</Typography></AccordionSummary>

                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <TextField margin="dense" label={t("Name")} fullWidth required variant="standard" value={props.template.name ?? ""}
                                onChange={(e) => props.setTemplate({ ...props.template, name: e.target.value })} error={!props.template.name} />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextField margin="dense" label="UPN" fullWidth required variant="standard" multiline error={!props.template.upns}
                                value={props.template.upns ?? ""} onChange={(e) => props.setTemplate({ ...props.template, upns: e.target.value })}
                                onBlur={() => props.setTemplate({ ...props.template, upns: upnPreview.toString() })}
                                placeholder="Can be separated by a space, newline, comma, or semicolon" />

                            {upnPreview.map((upn, index) => <Chip key={index} label={upn} variant="outlined" sx={{ m: 0.5 }} />)}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}