import { Assignment, AssignmentTurnedIn, Error, Home as HomeIcon } from '@mui/icons-material';
import { brown, red } from '@mui/material/colors';
import { useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FileProvider } from 'wcz-file';
import { LayoutProvider, LeftDrawerItem, User, hasRole, useUser } from 'wcz-layout';
import packageJson from '../package.json';
import Defects from './pages/Defects';
import Home from './pages/Home';
import Checklists from './pages/checklists/Checklists';
import UpdateChecklist from './pages/checklists/UpdateChecklist';
import ViewChecklist from './pages/checklists/ViewChecklist';
import CreateTemplate from './pages/templates/CreateTemplate';
import Templates from './pages/templates/Templates';
import UpdateTemplate from './pages/templates/UpdateTemplate';
import { fileUrl } from './utils/BaseUrl';
import { adminRole } from './utils/userRoles';

export default function App() {
    const user: User = useUser();

    const leftDrawerItems: LeftDrawerItem[] = useMemo(() => [
        { title: "Layout.Home", path: "/", icon: <HomeIcon fontSize="large" /> },
        { title: "Checklists", path: "/checklists", icon: <AssignmentTurnedIn fontSize="large" /> },
        { title: "Templates", path: "/templates", icon: <Assignment fontSize="large" />, hidden: !hasRole(adminRole) },
        { title: "Defects", path: "/defects", icon: <Error fontSize="large" /> },
    ], [user]);

    return (
        <BrowserRouter>
            <LayoutProvider title={packageJson.name} appVersion={packageJson.version} primaryColor={brown[600]} secondaryColor={red[500]} leftDrawerItems={leftDrawerItems}>
                <FileProvider baseUrl={fileUrl}>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/checklists' element={<Checklists />} />
                        <Route path='/checklist/edit/:id' element={<UpdateChecklist />} />
                        <Route path='/checklist/:id' element={<ViewChecklist />} />
                        <Route path='/templates' element={<Templates />} />
                        <Route path='/templates/:id' element={<Templates />} />
                        <Route path='/template/create' element={<CreateTemplate />} />
                        <Route path='/template/create/:id' element={<CreateTemplate />} />
                        <Route path='/template/:id' element={<UpdateTemplate />} />
                        <Route path='/defects' element={<Defects />} />
                    </Routes>
                </FileProvider>
            </LayoutProvider>
        </BrowserRouter>
    );
}