import { Autocomplete, Box, Checkbox, DialogContent, FormControlLabel, FormGroup, TextField } from "@mui/material";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { UploadFile, FileViewer } from 'wcz-file';
import { fetchGet, LayoutContext } from "wcz-layout";
import Defect from "../../../models/Defect";
import DefectCategory from "../../../models/DefectCategory";
import { apiUrl } from "../../../utils/BaseUrl";

interface DefectDialogContentProps {
    defect: Defect,
    setDefect: (defect: Defect) => void
}

export default function DefectDialogContent(props: DefectDialogContentProps) {
    const { i18n, t } = useContext(LayoutContext);

    const { data: categories = [] } = useQuery<DefectCategory[]>(["defectCategories"], ({ signal }) => fetchGet(`${apiUrl}/v1/defect/category`, signal), {
        enabled: !!props.defect.id,
        staleTime: 100000
    });

    return (
        <DialogContent sx={{ pt: 0 }}>
            <TextField fullWidth variant="standard" margin="dense" label={t("Description")}
                value={props.defect.description} onChange={e => props.setDefect({ ...props.defect, description: e.target.value })} required />

            <Autocomplete
                options={categories}
                value={props.defect.category}
                getOptionLabel={c => i18n.language === "cs" ? c.nameCz : c.name}
                onChange={(e, value) => value && props.setDefect({ ...props.defect, category: value })}
                renderInput={(params) => <TextField {...params} label={t("Categories")} fullWidth variant="standard" margin="dense" />}
            />

            <TextField fullWidth variant="standard" margin="dense" label={t("PersonInCharge")}
                value={props.defect.personInCharge} onChange={e => props.setDefect({ ...props.defect, personInCharge: e.target.value })} />

            <FormGroup sx={{ my: 1 }}>
                <FormControlLabel control={<Checkbox checked={props.defect.isFixed} onChange={e => props.setDefect({ ...props.defect, isFixed: e.target.checked })} />} label={t("Fixed")} />
            </FormGroup>

            {props.defect.id && 
                <Box sx={{ mt: 1 }}>
                <UploadFile subId={props.defect.id} />
                <FileViewer subId={props.defect.id} />
            </Box>
        }
            
        </DialogContent>
    );
}