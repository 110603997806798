import { Container, Grid } from "@mui/material";
import { Dictionary } from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { fetchGet, fetchPut, LayoutContext } from "wcz-layout";
import BackdropLoading from "../../components/common/BackdropLoading";
import TemplateDetail from "../../components/template/TemplateDetail";
import TemplateForm from "../../components/template/TemplateForm";
import Template from "../../models/Template";
import TemplateTask from "../../models/TemplateTask";
import { apiUrl } from "../../utils/BaseUrl";

export default function UpdateTemplate() {
    const { id } = useParams();
    const [template, setTemplate] = useState<Template>({} as Template);
    const { changeTitle, snackbar, user, t, i18n } = useContext(LayoutContext);
    const navigate = useNavigate();

    useQuery(["template", id], ({ signal }) => fetchGet(`${apiUrl}/v1/template/${id}`, signal), {
        onSuccess: (data: Template) => setTemplate(data),
    });

    useEffect(() => changeTitle(t("UpdateTemplate")), [i18n.language]);

    const { mutate: update, isLoading: isUpdating } = useMutation((req: Template) => fetchPut(`${apiUrl}/v1/template/${id}`, req), {
        onSuccess: () => { snackbar({ message: t("Updated"), severity: "success" }); navigate("/templates"); },
    });

    const updateTemplate = (groupedTasks: Dictionary<TemplateTask[]>) => {
        let newTasks: TemplateTask[] = [];

        Object.keys(groupedTasks).forEach((key) => {
            const stage: number = Number(key);
            newTasks = newTasks.concat(groupedTasks[stage]);
        });

        const indexedTasks = newTasks.map((task, index) => {
            return { ...task, position: index } as TemplateTask;
        });

        update({ ...template, tasks: indexedTasks, lastUpdated: moment().format(), lastUpdatedBy: `${user.name} (${user.id})` });
    };

    return (
        <Container>
            <Grid container sx={{ mt: 2 }}>
                <TemplateDetail template={template} setTemplate={setTemplate} />
            </Grid>
            <Grid container sx={{ mt: 2 }}>
                <TemplateForm tasks={template.tasks} send={updateTemplate} />
            </Grid>

            <BackdropLoading open={isUpdating} message="Updating template and it's checklists..." />
        </Container>
    );
}