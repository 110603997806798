import { Container, Grid } from "@mui/material";
import { Dictionary } from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { fetchGet, fetchPost, LayoutContext } from "wcz-layout";
import TemplateDetail from "../../components/template/TemplateDetail";
import TemplateForm from "../../components/template/TemplateForm";
import { TaskType } from "../../models/enums/TaskType";
import Template from "../../models/Template";
import TemplateTask from "../../models/TemplateTask";
import { apiUrl } from "../../utils/BaseUrl";

export default function CreateTemplate() {
    const { id } = useParams();
    const [template, setTemplate] = useState<Template>({} as Template);
    const { changeTitle, user, snackbar, t, i18n } = useContext(LayoutContext);
    const navigate = useNavigate();

    useQuery(["template", id], ({ signal }) => fetchGet(`${apiUrl}/v1/template/${id}`, signal), {
        enabled: !!id && !!user.name,
        onSuccess: (data: Template) => setTemplate(copyTemplate(data)),
    });

    useEffect(() => {
        changeTitle(t("CreateTemplate"));

        if (!id)
            initTemplate();

        // eslint-disable-next-line
    }, [i18n.language]);

    const initTemplate = () => {
        setTemplate({
            ...template, tasks: [
                { question: "", questionEn: "", stage: 1, type: TaskType.Confirm, isHighlighted: false, position: 0 },
                { question: "", questionEn: "", stage: 2, type: TaskType.Confirm, isHighlighted: false, position: 0 },
                { question: "", questionEn: "", stage: 3, type: TaskType.Confirm, isHighlighted: false, position: 0 },
                { question: "", questionEn: "", stage: 4, type: TaskType.Confirm, isHighlighted: false, position: 0 },
                { question: "", questionEn: "", stage: 5, type: TaskType.Confirm, isHighlighted: false, position: 0 },
            ]
        });
    };

    const copyTemplate = (template: Template): Template => {
        return {
            name: "",
            upns: "",
            tasks: template.tasks.map(task => ({
                ...task,
                id: undefined,
                templateId: undefined,
                lastUpdated: undefined,
                lastUpdatedBy: undefined,
                created: moment().format(),
                createdBy: `${user.name} (${user.id})`
            })),
            created: moment().format(),
            createdBy: `${user.name} (${user.id})`
        };
    };

    const { mutate: create } = useMutation((req: Template) => fetchPost(`${apiUrl}/v1/template`, req), {
        onSuccess: () => { snackbar({ message: t("Created"), severity: "success" }); navigate("/templates"); },
    });

    const createTemplate = (groupedTasks: Dictionary<TemplateTask[]>) => {
        let newTasks: TemplateTask[] = [];

        Object.keys(groupedTasks).forEach((key) => {
            const stage: number = Number(key);
            newTasks = newTasks.concat(groupedTasks[stage]);
        });

        const indexedTasks = newTasks.map((task, index) => {
            return { ...task, position: index } as TemplateTask;
        });

        create({ ...template, tasks: indexedTasks });
    };

    return (
        <Container>
            <Grid container sx={{ mt: 2 }}>
                <TemplateDetail template={template} setTemplate={setTemplate} />
            </Grid>
            <Grid container sx={{ mt: 2 }}>
                <TemplateForm tasks={template.tasks} send={createTemplate} />
            </Grid>
        </Container>
    );
}