import { LicenseInfo } from "@mui/x-data-grid-premium";
import { BarcodeReader } from "dynamsoft-javascript-barcode";
import { createRoot } from 'react-dom/client';
import packageJson from "../package.json";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

LicenseInfo.setLicenseKey(packageJson.MuiXLicence);
BarcodeReader.license = packageJson.DynamsoftLicence;
BarcodeReader.engineResourcePath = `https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@${packageJson.dependencies['dynamsoft-javascript-barcode']}/dist/`;

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
