import { CheckBox, CheckBoxOutlineBlank, Error } from "@mui/icons-material";
import { Box, Button, Card, CardActionArea, CardContent, CardHeader, Collapse, Container, Divider, Grid, List, ListItem, Typography } from "@mui/material";
import { groupBy } from "lodash";
import moment from "moment";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { FileViewer } from "wcz-file";
import { fetchGet, fetchPut, hasRole, LayoutContext } from "wcz-layout";
import UpdateDefect from "../../components/defect/UpdateDefect";
import Checklist from "../../models/Checklist";
import Defect from "../../models/Defect";
import { ChecklistStatus } from "../../models/enums/ChecklistStatus";
import { TaskType } from "../../models/enums/TaskType";
import { apiUrl } from "../../utils/BaseUrl";
import { adminRole } from "../../utils/userRoles";

const getChecklistWithoutChildren = (checklist: Checklist): Checklist => {
    return { ...checklist, tasks: [] };
};

export default function ViewChecklist() {
    const { id } = useParams();
    const [checklist, setChecklist] = useState<Checklist>({} as Checklist);
    const [editDefect, setEditDefect] = useState<Defect>({} as Defect);
    const { changeTitle, snackbar, user, i18n, t } = useContext(LayoutContext);

    const { refetch } = useQuery<Checklist>(["checklist", id], ({ signal }) => fetchGet(`${apiUrl}/v1/checklist/${id}`, signal), {
        onSuccess: data => { setChecklist(data); changeTitle(data.usn); }
    });

    const { mutate: update } = useMutation((req: Checklist) => fetchPut(`${apiUrl}/v1/checklist/${id}`, getChecklistWithoutChildren(req)), {
        onSuccess: () => snackbar({ message: t("Opened"), severity: "success" }),
        onError: (message: string) => { snackbar({ message: message, severity: "error" }); refetch(); }
    });

    const openChecklist = () => {
        const updatedChecklist: Checklist = {
            ...checklist,
            status: ChecklistStatus.InProgress,
            lastUpdated: moment().format(),
            lastUpdatedBy: `${user.name} (${user.id})`
        };
        setChecklist(updatedChecklist);
        update(updatedChecklist);
    };

    return (
        <Container>
            <Grid container sx={{ pb: 7 }}>
                <Grid item xs={12} sx={{ my: 1 }}>
                    <Typography><b>USN: </b> {checklist.usn}</Typography>
                    <Typography><b>{t("Template")}:</b> <Link to={"/templates/" + checklist?.templateId}>{checklist?.upn}</Link></Typography>
                </Grid>

                {Object.keys(groupBy(checklist.tasks, "stage")).map(key => {
                    const stage: number = Number(key);

                    return (
                        <Grid item xs={12} key={stage}>
                            <Typography variant="h5" sx={{ mt: 2 }}>Stage: {stage}</Typography>

                            {groupBy(checklist.tasks, "stage")[stage].map(task =>
                                <Card variant="outlined" key={task.id} sx={(theme) => ({
                                    background: task.isHighlighted ? theme.palette.warning.main : undefined,
                                    color: task.isHighlighted ? theme.palette.info.contrastText : undefined,
                                    my: 0.5
                                })}>
                                    <CardActionArea>
                                        <CardHeader title={i18n.language === "cs" ? task.question : task.questionEn ?? task.question}
                                            subheader={task.lastUpdated && `${t("Updated")}: ${moment(task.lastUpdated).formatDateTime()}, ${task.lastUpdatedBy}`}
                                            avatar={
                                                <Box>
                                                    {task.isAnswered ? <CheckBox color="success" /> : <CheckBoxOutlineBlank />}
                                                </Box>
                                            } />
                                    </CardActionArea>

                                    <FileViewer subId={task.templateTaskId} disableActions />

                                    {task.type === TaskType.Photo &&
                                        <Box sx={{ p: 1 }}>
                                            <FileViewer subId={task.id as string} disableActions />
                                        </Box>
                                    }

                                    {task.type === TaskType.Scan &&
                                        <CardContent sx={{ py: 0 }}>
                                            {!!task.scans.length &&
                                                <List dense disablePadding sx={{ mb: 2 }}>
                                                    {task.scans.map((scan, index) =>
                                                        <ListItem disablePadding key={scan.id}>
                                                            <Typography gutterBottom>Tower {index + 1}: <b>{scan.value}</b></Typography>
                                                        </ListItem>
                                                    )}
                                                </List>
                                            }
                                        </CardContent>
                                    }

                                    <Collapse in={!!task.defects?.length} timeout="auto" unmountOnExit sx={{ px: 2 }}>
                                        <Divider />
                                        <Grid container>
                                            {task.defects?.map(defect =>
                                                <Grid item xs={12} key={defect.id}>
                                                    <CardActionArea onClick={() => setEditDefect(defect)}>
                                                        <CardHeader title={defect.description} subheader={i18n.language === "cs" ? defect.category.nameCz : defect.category.name}
                                                            action={<Error color="error" fontSize="large" />}
                                                            avatar={
                                                                <Box>
                                                                    {defect.isFixed ? <CheckBox color="success" /> : <CheckBoxOutlineBlank />}
                                                                </Box>
                                                            } />
                                                    </CardActionArea>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Collapse>
                                </Card>
                            )}
                        </Grid>
                    );
                })}

                {hasRole(adminRole) &&
                    <Grid item xs={12} sx={{ mt: 2, textAlign: 'center' }}>
                        {checklist.status === ChecklistStatus.Finished && <Button variant="contained" onClick={openChecklist}>{t("Open")}</Button>}
                    </Grid>
                }
            </Grid>

            <UpdateDefect checklist={checklist} setChecklist={setChecklist} refetch={refetch} editDefect={editDefect} setEditDefect={setEditDefect} />
        </Container>
    );
}