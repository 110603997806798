import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogTitle, Grid, IconButton } from "@mui/material";
import moment from "moment";
import { useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { fetchDelete, fetchPut, LayoutContext } from "wcz-layout";
import Checklist from "../../models/Checklist";
import ChecklistTask from "../../models/ChecklistTask";
import Defect from "../../models/Defect";
import { apiUrl } from "../../utils/BaseUrl";
import DefectDialogContent from "./common/DefectDialogContent";

interface UpdateDefectProps {
    editDefect: Defect,
    setEditDefect: (editDefect: Defect) => void,
    checklist: Checklist,
    setChecklist: (checklist: Checklist) => void,
    refetch: () => void
}

export default function UpdateDefect(props: UpdateDefectProps) {
    const { snackbar, user, t } = useContext(LayoutContext);

    const onClose = () => {
        props.setEditDefect({} as Defect);
    };

    const { mutate: update } = useMutation((req: Defect) => fetchPut(`${apiUrl}/v1/defect/${req.id}`, req), {
        onError: (message: string) => { snackbar({ message: message, severity: "error" }); props.refetch(); }
    });

    const handleOnSave = () => {
        const updatedTasks: ChecklistTask[] = props.checklist.tasks.map(task => {
            if (task.id === props.editDefect.checklistTaskId) {
                const updatedDefects: Defect[] = task.defects.map(defect => {
                    if (defect.id === props.editDefect.id) {
                        const updatedDefect: Defect = { ...props.editDefect, lastUpdated: moment().format(), lastUpdatedBy: `${user.name} (${user.id})` };
                        update(updatedDefect);
                        return updatedDefect;
                    } else {
                        return defect;
                    }
                });
                return { ...task, defects: updatedDefects };
            } else {
                return task;
            }
        });

        const updatedChecklist: Checklist = { ...props.checklist, tasks: updatedTasks };
        props.setChecklist(updatedChecklist);
        onClose();
    };

    const { mutate: remove } = useMutation((id: string) => fetchDelete(`${apiUrl}/v1/defect/${id}`), {
        onError: (message: string) => { snackbar({ message: message, severity: "error" }); props.refetch(); }
    });

    const handleOnRemove = () => {
        if (!window.confirm("Are you sure you want to remove this defect?"))
            return;

        const updatedTasks: ChecklistTask[] = props.checklist.tasks.map(task => {
            if (task.id === props.editDefect.checklistTaskId) {
                const updatedDefects: Defect[] = task.defects.filter(defect => defect.id !== props.editDefect.id);
                remove(props.editDefect.id as string);
                return { ...task, defects: updatedDefects };
            } else {
                return task;
            }
        });

        const updatedChecklist: Checklist = { ...props.checklist, tasks: updatedTasks };
        props.setChecklist(updatedChecklist);
        onClose();
    };

    return (
        <Dialog open={!!props.editDefect.id} onClose={onClose} fullWidth>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <DialogTitle>{t("UpdateDefect")}</DialogTitle>
                </Grid>

                <Grid item sx={{ p: 1 }}>
                    <IconButton onClick={onClose}>
                        <Close fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>

            <DefectDialogContent defect={props.editDefect} setDefect={props.setEditDefect} />

            <DialogActions>
                <Button color="error" onClick={handleOnRemove}>{t("Delete")}</Button>
                <Button variant="contained" onClick={handleOnSave}>{t("Save")}</Button>
            </DialogActions>
        </Dialog>
    );
}