import { Close } from "@mui/icons-material";
import { AppBar, Box, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import { BarcodeScanner } from "dynamsoft-javascript-barcode";
import React, { useEffect, useRef } from 'react';

let scanner: BarcodeScanner | null = null;

interface DynamsoftScannerProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    onScan: (value: string) => void,
    disableAutoclose?: boolean
}

export const DynamsoftScanner: React.FC<DynamsoftScannerProps> = ({ open, setOpen, onScan, disableAutoclose }) => {
    const elRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (open)
            initScannerAsync();
    }, [open]);

    const initScannerAsync = async () => {
        scanner = await BarcodeScanner.createInstance();
        await scanner.setUIElement(elRef.current!);
        scanner.onUniqueRead = (txt) => { onScan(txt); !disableAutoclose && onClose(); };
        await scanner.open();
    };

    const onClose = () => {
        setOpen(false);

        if (scanner)
            scanner.destroyContext();
    };

    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose}>
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Scan</Typography>
                </Toolbar>
            </AppBar>
            <Box ref={elRef} sx={{ width: "100vw", height: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }, position: 'relative' }} className="dce-video-container"></Box>
        </Dialog>
    );
};