import { CloudDownload, Create, Visibility } from '@mui/icons-material';
import { DataGridPremium, GridActionsCellItem, GridColDef } from '@mui/x-data-grid-premium';
import { GridToolbar, GridToolbarProps, TableContainer } from 'wcz-x-data-grid';
import saveAs from 'file-saver';
import React, { useContext, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { fetchFileGet, fetchGet, LayoutContext } from 'wcz-layout';
import Checklist from '../../models/Checklist';
import { ChecklistStatus } from '../../models/enums/ChecklistStatus';
import { apiUrl } from '../../utils/BaseUrl';
import moment from 'moment';

export default function Checklists() {
    const { changeTitle, i18n, t } = useContext(LayoutContext);
    const navigate = useNavigate();

    const { data: checklists = [] } = useQuery<Checklist[]>(["checklists"], ({ signal }) => fetchGet(`${apiUrl}/v1/checklist`, signal));

    useEffect(() => changeTitle(t("Checklists")), [i18n.language]);

    const exportChecklists = async () => {
        const controller = new AbortController();
        const exportedChecklists = await fetchFileGet(`${apiUrl}/v1/checklist/export`, controller.signal);
        saveAs(exportedChecklists, 'checklists');
    };

    const exportChecklist = async (id: string, usn: string) => {
        const controller = new AbortController();
        const exportedChecklist = await fetchFileGet(`${apiUrl}/v1/checklist/export/${id}`, controller.signal);
        saveAs(exportedChecklist, "Checklist-" + usn);
    };

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', width: 50, maxWidth: 500,
            getActions: (params: any) => [
                <GridActionsCellItem key="create" icon={<Create />} label={t("Edit")} onClick={() => navigate(`/checklist/edit/${params.id}`)} showInMenu disabled={params.row.status === ChecklistStatus.Finished} />,
                <GridActionsCellItem key="visibility" icon={<Visibility />} label="Report" onClick={() => navigate(`/checklist/${params.id}`)} showInMenu />,
                <GridActionsCellItem key="excel" icon={<CloudDownload />} label="Excel" onClick={() => exportChecklist(params.id, params.row.usn)} showInMenu />,
            ],
        },
        { field: 'usn', headerName: "USN", width: 200, maxWidth: 500, },
        { field: 'stage', headerName: "Stage", width: 100, maxWidth: 500, type: 'number' },
        { field: 'status', headerName: "Status", width: 150, maxWidth: 500, type: 'singleSelect', valueOptions: Object.values(ChecklistStatus), },
        { field: 'upn', headerName: "UPN", width: 200, maxWidth: 500, },
        { field: 'created', headerName: t("Created"), width: 140, maxWidth: 500, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'createdBy', headerName: t("CreatedBy"), width: 220, maxWidth: 500, },
        { field: 'lastUpdated', headerName: t("Updated"), width: 140, maxWidth: 500, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'lastUpdatedBy', headerName: t("UpdatedBy"), width: 220, maxWidth: 500, },
    ] as GridColDef[], [i18n.language]);

    return (
        <TableContainer>
            <DataGridPremium rows={checklists} columns={columns} slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        items: [{ title: "Excel", onClick: () => exportChecklists(), icon: <CloudDownload /> }],
                    } as GridToolbarProps,
                }}
            />
        </TableContainer>
    );
}