import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogTitle, Grid, IconButton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { fetchPost, LayoutContext, newGuid } from "wcz-layout";
import Checklist from "../../models/Checklist";
import ChecklistTask from "../../models/ChecklistTask";
import Defect from "../../models/Defect";
import { apiUrl } from "../../utils/BaseUrl";
import DefectDialogContent from "./common/DefectDialogContent";

interface CreateDefectProps {
    addDefectTaskId: string,
    setAddDefectTaskId: (addDefectTaskId: string) => void,
    checklist: Checklist,
    setChecklist: (checklist: Checklist) => void,
    refetch: () => void
}

export default function CreateDefect(props: CreateDefectProps) {
    const [defect, setDefect] = useState<Defect>({} as Defect);
    const { snackbar, t } = useContext(LayoutContext);

    useEffect(() => {
        if (props.addDefectTaskId)
            setDefect({
                ...defect,
                id: newGuid(),
                checklistTaskId: props.addDefectTaskId,
                usn: props.checklist.usn,
                createdOnStage: props.checklist.stage
            });

        // eslint-disable-next-line
    }, [props.addDefectTaskId]);

    const { mutate: create } = useMutation((req: Defect) => fetchPost(`${apiUrl}/v1/defect`, req), {
        onSuccess: data => handleOnCreate(data),
        onError: (message: string) => { snackbar({ message: message, severity: "error" }); props.refetch(); }
    });

    const onClose = () => {
        props.setAddDefectTaskId("");
        setDefect({} as Defect);
    };

    const handleOnCreate = (newDefect: Defect) => {
        const updatedTasks: ChecklistTask[] = props.checklist.tasks.map(task => {
            if (task.id === defect.checklistTaskId) {
                return { ...task, defects: [...task.defects, newDefect] };
            } else {
                return task;
            }
        });

        const updatedChecklist: Checklist = { ...props.checklist, tasks: updatedTasks };
        props.setChecklist(updatedChecklist);
        onClose();
    };

    return (
        <Dialog open={!!props.addDefectTaskId} onClose={onClose} fullWidth>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <DialogTitle>{t("CreateDefect")}</DialogTitle>
                </Grid>

                <Grid item sx={{ p: 1 }}>
                    <IconButton onClick={onClose}>
                        <Close fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>

            <DefectDialogContent defect={defect} setDefect={setDefect} />

            <DialogActions>
                <Button variant="contained" onClick={() => create(defect)} disabled={!defect.description || !defect.category?.id}>{t("Save")}</Button>
            </DialogActions>
        </Dialog>
    );
}