import { Add, ArrowDownward, ArrowUpward, Delete, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Dictionary, groupBy, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { FileViewer, UploadFile } from 'wcz-file';
import { LayoutContext, newGuid } from 'wcz-layout';
import { TaskType } from '../../models/enums/TaskType';
import TemplateTask from '../../models/TemplateTask';

interface TemplateFormProps {
    tasks: TemplateTask[],
    send: (groupedTasks: Dictionary<TemplateTask[]>) => void
}

export default function TemplateForm(props: TemplateFormProps) {
    const [groupedTasks, setGroupedTasks] = useState({} as Dictionary<TemplateTask[]>);
    const { user, t } = useContext(LayoutContext);

    useEffect(() => {
        if (isEmpty(groupedTasks) && !isEmpty(props.tasks))
            setGroupedTasks(groupBy(props.tasks, "stage"));

        // eslint-disable-next-line
    }, [props.tasks]);

    const handleOnQuestionChange = (value: string, stage: number, index: number) => {
        const editGroupedTasks = { ...groupedTasks };
        editGroupedTasks[stage][index].question = value;
        editGroupedTasks[stage][index].lastUpdated = moment().format();
        editGroupedTasks[stage][index].lastUpdatedBy = `${user.name} (${user.id})`;
        setGroupedTasks(editGroupedTasks);
    };

    const handleOnQuestionEnChange = (value: string, stage: number, index: number) => {
        const editGroupedTasks = { ...groupedTasks };
        editGroupedTasks[stage][index].questionEn = value;
        editGroupedTasks[stage][index].lastUpdated = moment().format();
        editGroupedTasks[stage][index].lastUpdatedBy = `${user.name} (${user.id})`;
        setGroupedTasks(editGroupedTasks);
    };

    const handleOnTypeChange = (value: TaskType, stage: number, index: number) => {
        const editGroupedTasks = { ...groupedTasks };
        editGroupedTasks[stage][index].type = value;
        editGroupedTasks[stage][index].lastUpdated = moment().format();
        editGroupedTasks[stage][index].lastUpdatedBy = `${user.name} (${user.id})`;
        setGroupedTasks(editGroupedTasks);
    };

    const handleOnIsHighlightedChange = (value: boolean, stage: number, index: number) => {
        const editGroupedTasks = { ...groupedTasks };
        editGroupedTasks[stage][index].isHighlighted = value;
        editGroupedTasks[stage][index].lastUpdated = moment().format();
        editGroupedTasks[stage][index].lastUpdatedBy = `${user.name} (${user.id})`;
        setGroupedTasks(editGroupedTasks);
    };

    const handleOnIndexUp = (stage: number, index: number) => {
        const editGroupedTasks = { ...groupedTasks };

        const lowerElement = editGroupedTasks[stage][index - 1];
        const element = editGroupedTasks[stage][index];

        editGroupedTasks[stage][index - 1] = element;
        editGroupedTasks[stage][index] = lowerElement;

        setGroupedTasks(editGroupedTasks);
    };

    const handleOnIndexDown = (stage: number, index: number) => {
        const editGroupedTasks = { ...groupedTasks };

        const higherElement = editGroupedTasks[stage][index + 1];
        const element = editGroupedTasks[stage][index];

        editGroupedTasks[stage][index + 1] = element;
        editGroupedTasks[stage][index] = higherElement;

        setGroupedTasks(editGroupedTasks);
    };

    const handleOnDelete = (stage: number, index: number) => {
        const editGroupedTasks = { ...groupedTasks };
        editGroupedTasks[stage].splice(index, 1);
        setGroupedTasks(editGroupedTasks);
    };

    const handleOnAdd = (stage: number) => {
        const editGroupedTasks = { ...groupedTasks };
        const newTask: TemplateTask = { id: newGuid(), question: "", questionEn: "", stage: stage, type: TaskType.Confirm, isHighlighted: false, position: 0 };
        editGroupedTasks[stage].push(newTask);
        setGroupedTasks(editGroupedTasks);
    };

    const getTransformTasksToStageGroups = () => {
        return Object.keys(groupedTasks).map((key) => {
            const stage: number = Number(key);

            const tasksByStage = groupedTasks[stage].map((task, index) =>
                <AccordionDetails key={index} >
                    <Grid container>
                        <Grid item xs={2} md={1} sx={{ mx: 'auto' }}>
                            <IconButton onClick={() => handleOnIndexUp(stage, index)} disabled={!index}>
                                <ArrowUpward />
                            </IconButton>
                            <IconButton onClick={() => handleOnIndexDown(stage, index)} disabled={index + 1 === groupedTasks[stage].length}>
                                <ArrowDownward />
                            </IconButton>
                        </Grid>

                        <Grid item xs={10} md={11} sx={{ mb: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <TextField margin="dense" label={t("Question")} fullWidth value={task.question} multiline required variant="standard"
                                        onChange={(e) => handleOnQuestionChange(e.target.value, stage, index)} />
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <TextField margin="dense" label={t("Question") + " EN"} fullWidth value={task.questionEn} multiline variant="standard"
                                        onChange={(e) => handleOnQuestionEnChange(e.target.value, stage, index)} />
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 2 }}>
                                <Grid item md={11} xs={12}>
                                    <FormControl variant="standard">
                                        <Select value={task.type} onChange={(e) => handleOnTypeChange(e.target.value as TaskType, stage, index)}>
                                            {Object.values(TaskType).map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)}
                                        </Select>
                                    </FormControl>

                                    <FormControlLabel label={t("Highlight")} control={<Checkbox checked={task.isHighlighted} sx={{ ml: 3 }}
                                        onChange={(e) => handleOnIsHighlightedChange(e.target.checked, stage, index)} color="default" />} />
                                </Grid>
                                <Grid item md={1} xs={12}>
                                    <IconButton onClick={() => handleOnAdd(stage)} disabled={index + 1 !== groupedTasks[stage].length}>
                                        <Add />
                                    </IconButton>
                                    <IconButton onClick={() => handleOnDelete(stage, index)} disabled={groupedTasks[stage].length === 1}>
                                        <Delete />
                                    </IconButton>
                                </Grid>

                                <Grid item xs={12}>
                                    <UploadFile subId={task.id as string} />
                                    <FileViewer subId={task.id as string} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </AccordionDetails>
            );

            return (
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}><Typography variant="h6">Stage {stage}</Typography></AccordionSummary>
                    {tasksByStage}
                </Accordion>
            );
        });
    };

    return (
        <Grid item xs={12}>
            {getTransformTasksToStageGroups()}

            <Grid item md={4} sm={6} sx={{ mx: 'auto', my: 2 }}>
                <Button variant="contained" color="primary" fullWidth onClick={() => props.send(groupedTasks)}>{t("Save")}</Button>
            </Grid>
        </Grid>
    );
}