import { Add, CloudDownload, ContentCopy, Create } from '@mui/icons-material';
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid-premium';
import { useMutation, useQuery } from '@tanstack/react-query';
import saveAs from 'file-saver';
import moment from 'moment';
import { useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchDelete, fetchFileGet, fetchGet, LayoutContext } from 'wcz-layout';
import { GridDeleteCellItem, GridToolbar, GridToolbarProps, TableContainer } from 'wcz-x-data-grid';
import Template from '../../models/Template';
import { apiUrl } from '../../utils/BaseUrl';

export default function Templates() {
    const { id } = useParams();
    const { changeTitle, snackbar, t, i18n } = useContext(LayoutContext);
    const navigate = useNavigate();

    const { data: templates = [], refetch } = useQuery<Template[]>(["templates"], ({ signal }) => fetchGet(`${apiUrl}/v1/template`, signal));

    useEffect(() => changeTitle(t("Templates")), [i18n.language]);

    const remove = useMutation((id: GridRowId) => fetchDelete(`${apiUrl}/v1/template/${id}`), {
        onError: (message: string) => { snackbar({ message: message, severity: "error" }); refetch(); }
    });

    const exportTemplate = async (id: string, name: string) => {
        const controller = new AbortController();
        const exportedChecklist = await fetchFileGet(`${apiUrl}/v1/template/export/${id}`, controller.signal);
        saveAs(exportedChecklist, "Template-" + name);
    };

    const columns: GridColDef[] = useMemo(() => [
        {
            field: 'id', type: 'actions', filterable: true, width: 50, maxWidth: 500,
            getActions: (params: any) => [
                <GridActionsCellItem key="create" icon={<Create />} label={t("Edit")} onClick={() => navigate(`/template/${params.id}`)} showInMenu />,
                <GridActionsCellItem key="copy" icon={<ContentCopy />} label={t("Copy")} onClick={() => navigate(`/template/create/${params.id}`)} showInMenu />,
                <GridActionsCellItem key="excel" icon={<CloudDownload />} label="Excel" onClick={() => exportTemplate(params.id, params.row.name)} showInMenu />,
                <GridDeleteCellItem key="delete" id={params.id} remove={remove} showInMenu />
            ],
        },
        { field: 'name', headerName: t("Name"), width: 250, maxWidth: 500, },
        { field: 'upns', headerName: "UPNS", width: 500, maxWidth: 900, },
        { field: 'created', headerName: t("Created"), width: 140, maxWidth: 500, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'createdBy', headerName: t("CreatedBy"), width: 220, maxWidth: 500, },
        { field: 'lastUpdated', headerName: t("Updated"), width: 140, maxWidth: 500, type: 'date', valueFormatter: ({ value }) => moment(value).formatDateTime() },
        { field: 'lastUpdatedBy', headerName: t("UpdatedBy"), width: 220, maxWidth: 500, },
    ] as GridColDef[], [i18n.language]);

    return (
        <TableContainer>
            <DataGridPremium rows={templates} columns={columns} slots={{ toolbar: GridToolbar }}
                initialState={{
                    filter: id === undefined ? undefined : {
                        filterModel: {
                            items: [{ field: 'id', value: id, operator: 'equals' }]
                        }
                    }
                }}
                slotProps={{
                    toolbar: {
                        items: [{ icon: <Add />, title: t("Create"), onClick: () => navigate("/template/create") }],
                    } as GridToolbarProps,
                }}
            />
        </TableContainer>
    );
}