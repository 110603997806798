import { Done, QrCode } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import React, { Fragment, useCallback, useState } from "react";
import ChecklistTask from "../../models/ChecklistTask";
import { DynamsoftScanner } from "../common/DynamsoftScanner";

interface TowerInputProps {
    task: ChecklistTask;
    addScan: (id: string, value: string) => void;
}

export const TowerInput: React.FC<TowerInputProps> = ({ task, addScan }) => {
    const [scanValue, setScanValue] = useState<string>("");
    const [scanOpen, setScanOpen] = useState<boolean>(false);

    const handleOnScannerClick = useCallback(() => setScanOpen(true), []);

    const handleOnScan = useCallback((value: string) => {
        addScan(task.id as string, value);
    }, [task.id]);

    const handleOnDoneClick = useCallback(() => {
        setScanValue("");
        addScan(task.id as string, scanValue);
    }, [task.id, scanValue]);

    const handleOnKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" && scanValue) {
            setScanValue("");
            addScan(task.id as string, scanValue);
        }
    }, [task.id, scanValue]);

    return (
        <Fragment>
            <TextField size="small" label={`Tower ${task.scans.length + 1}`} value={scanValue} onChange={e => setScanValue(e.target.value)} onKeyDown={handleOnKeyDown} InputProps={{
                startAdornment: <IconButton size="small" onClick={handleOnScannerClick}><QrCode /></IconButton>,
                endAdornment: <IconButton disabled={!scanValue} size="small" onClick={handleOnDoneClick}><Done /></IconButton>
            }} />

            <DynamsoftScanner open={scanOpen} setOpen={setScanOpen} onScan={handleOnScan} />
        </Fragment>

    );
};